import React, { Component } from 'react';
import '../App.css'
export default class IYG extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="about">
                <div className="row">

                    <div className="three columns">
                        <a href="https://www.igniteyourgnost.com" target="_blank" rel="noopener noreferrer">
                        <img className="IYG-pic" src="images/igniteyourgnost.png" alt="IgniteYourGnost.com" style={{borderRadius: '5px'}}/>
                        </a>
                    </div>

                    <div className="nine columns main-col">

                        <a href="https://www.igniteyourgnost.com" target="_blank" rel="noopener noreferrer">
                            <h2>IgniteYourGnost.com</h2>
                        </a>
                        <p>
                            {
                                resumeData.IYG
                            }
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}