let resumeData = {
  "imagebaseurl": "",
  "name": "Kandy Stanley",
  "role": "Certified Usui Reiki Master",
  "linkedinId": "null",
  "skypeid": "null",
  "roleDescription": "",
  "email": "kandystanleyreiki@gmail.com",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/",
      "className": "fa fa-github"
    },
  ],
  "aboutme": "Kandy is a versatile energy expert who excels in visionary teaching and remote healing, enabling reconnection and healing at all levels of existence. She bridges consciousness, awakens souls, and acts as a catalyst for high-vibrational change, resulting in quantum growth, abundance, healing, and instant transformation and manifestation for millions of clients worldwide.",
  "IYG": "information",
  "address": "Emory, Texas",
  "education": [
    {
      "UniversityName": "Healing Hands",
      "specialization": "Reiki Healing",
      "MonthOfPassing": "Mar",
      "YearOfPassing": "2010",
      "Achievements": "Usui Reiki Master"
    },
    
  ],
  "work": [
    {
      "CompanyName": "IgniteYourGnost",
      "specialization": "Certified Usui Reiki Master",
      "MonthOfLeaving": "Currently",
      "YearOfLeaving": "Employed",
      "Achievements": ""
    },
    
  ],
  "skills": [
    {
      "skillname": "jQuery"
    },
    
  ],
  "portfolio": [
    {
      "name": "Coming Soon",
      "description": "Lots of really cool stuff coming soon!",
      "imgurl": "/retrocam.jpg"
    },
    
  ],
  "testimonials": [
    {
      "description": "I've had the pleasure of working with Kandy for the past year, and the impact on my life has been profound. Her reiki healing sessions have helped a lot with my chronic pain and brought a sense of inner peace and clarity that I've been seeking for years. I highly recommend her!",
      "name": "Sarah Johnson"
    },
    {
      "description": "I was searching for something but I skeptical. I always assumed reiki was woo-woo psuedoscience. Kandy changed my mind. Her reiki treatments have been a great experience for me. Definitely check her out.",
      "name": "Michael Anderson"
    },
    {
      "description": "Kandy's reiki healing sessions are wonderful. <3",
      "name": "Emily Davis"
    },
    {
      "description": "Tried a reiki session, didn't notice much of a difference. Kandy was great to work with, reiki just wasn't for me.",
      "name": "Robert Thompson"
    },
    {
      "description": "Kandy has transformed my life in ways I never thought possible. She truly has a gift for reiki healing. Her sessions have helped me connect with my inner self and heal old wounds. Kandy is amazing and I am forever grateful to her.",
      "name": "Lisa Martinez"
    }
  ]
}

export default resumeData;